import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Select, Divider, Image, Upload, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

function CreateUpdateCategoryForm({
  selectedCategoryItem = {},
  tagList,
  categoryList,
  languageList,
  onSubmit,
}) {
  const isUpdate = !!selectedCategoryItem?.id;
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    selectedCategoryItem.image && setImageUrl(selectedCategoryItem.image);
  }, [selectedCategoryItem]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleSubmit = async values => {
    onSubmit(values);
  };

  const handleImageChange = info => {
    getBase64(info.file.originFileObj, imageUrl => setImageUrl(imageUrl));
  };

  return (
    <Form
      onFinish={handleSubmit}
      name="createUpdateCategoryForm"
      className="form-wrapper"
      layout="vertical"
      size="large"
      initialValues={selectedCategoryItem}>
      <Row>
        <Col span={12}>
          {languageList.map(lan => (
            <Form.Item
              key={lan.id}
              name={`name.${lan.id}`}
              label={`Name in ${lan.name}`}
              rules={[{ required: true, message: `Please enter ${lan.name} category name!` }]}>
              <Input placeholder={`category name in ${lan.name}`} />
            </Form.Item>
          ))}
        </Col>
      </Row>
      <Divider />
      {/* <Row>
        <Col span={12}>
          <Form.Item
            label="Tag"
            name="tagIds"
            rules={[{ required: true, message: 'Please select the tag!' }]}>
            <Select placeholder="Select a tag" mode="multiple">
              {tagList.map(tag => (
                <Option value={tag.id} key={tag.id}>
                  {tag.translations.map((translation, index) => (
                    <>
                      {translation.lanName}: <b>{translation.name}</b>{' '}
                      {index !== tag.translations.length - 1 && '- '}
                    </>
                  ))}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider /> */}
      <Row>
        <Col span={12}>
          <Form.Item label="Category Image:" name="image" className="product-image-upload">
            <Upload
              listType="picture-card"
              showUploadList={false}
              onPreview={file => console.log(file)}
              onChange={handleImageChange}>
              {imageUrl ? (
                <Image src={imageUrl} alt="avatar" style={{ width: '100%' }} />
              ) : (
                <>
                  <PlusOutlined type="plus" />
                  <div className="ant-upload-text">Upload</div>
                </>
              )}
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <Row className="m-t-30 f-r">
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Save
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
}

export default CreateUpdateCategoryForm;
