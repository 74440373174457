import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Row, notification, Form, Col, Button } from 'antd';
import axios from 'axios';
import store from 'store';
import { baseURL } from '../../utils/helpers';

import ImagesUploader from '../../components/ImagesUploader';

import { useFetch, useMenu } from '../../hooks';
import { LayoutContext } from '../../contexts';
import { URLS } from '../../constants';

const authToken = store.get('authenticationToken');

function Banners() {
  const navigate = useNavigate()
  const { setHeaderComponent } = useContext(LayoutContext);
  const { doFetch: updateBannerImagesFetch } = useFetch();
  const menuData = useMenu();

  const [uploadFileList, setUploadFileList] = useState([]);  // State in the parent to manage the file list
  const [deleteFileList, setDeleteFileList] = useState([]);

  const initialImages = menuData.bannerImagesList.map((image) => ({
    uid: image.id, // Use the ID as uid to keep it unique
    name: `Image ${image.id}`, // Placeholder name
    status: 'done', // Mark these as already uploaded
    url: `${baseURL}/files/banner-images/${image.id}`, // The link to the image
  }));

  // Handle changes in the file list, passed to the child component
  const handleUploadFileListChange = (newFileList) => {
    setUploadFileList(newFileList); // Update the file list in the parent
  };

  useEffect(() => {
    setHeaderComponent(<b>{`Banners`}</b>);

    return () => {
      setHeaderComponent(null);
    };
  }, []);

  const handleOnSuccess = () => {
    notification.success({
      placement: 'bottomRight',
      message: 'Banners updated successfully.',
      duration: 3,
    });
    navigate('/');
    return;
  }

  const updateBannerImages = async (formValues) => {
    const formData = new FormData();

    // Add new images (files) to formData
    uploadFileList.forEach((file) => {
      if (!file.url) { // Only add newly uploaded images, not existing ones
        formData.append('upload_images', file.originFileObj); // Ensure you're sending the file object
      }
    });

    // Add deleted image IDs to formData
    deleteFileList.forEach((file) => {
      formData.append('delete_images', file.uid); // Send the ID of the deleted images
    });

    try {
      const response = await axios.post(`${baseURL}/${URLS.manageBannerImages()}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `${authToken}`,
        },
      });
      handleOnSuccess();
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  };

  return (
    <Form onFinish={updateBannerImages}>
      <Row>
        <Col span={12}>
          <Form.Item label="Banner Images:" className="product-image-upload">
          <ImagesUploader 
              fileList={uploadFileList} // Pass the uploadFileList to the child component
              setFileList={handleUploadFileListChange} // Pass the callback to update uploadFileList
              initialImages={initialImages}
              deletedImages={deleteFileList}
              setDeletedImages={setDeleteFileList}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className="m-t-30 f-r">
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Save
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
}

export default Banners;
