import React, { useEffect, useState, useRef } from 'react';
import { Upload, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { baseURL } from '../../utils/helpers';

const ImagesUploader = ({ initialImages = [], fileList, setFileList, deletedImages, setDeletedImages }) => {
  const [previewImage, setPreviewImage] = useState(''); // State for preview image
  const [previewVisible, setPreviewVisible] = useState(false); // State to control preview modal
  const hasLoadedInitialImages = useRef(false); // Track if initial images have been loaded

  // On component mount, load initial images into the fileList state
  useEffect(() => {
    if (!hasLoadedInitialImages.current && initialImages.length > 0) {
      // Only set fileList if it's the first render and there are initial images
      setFileList(initialImages);
      hasLoadedInitialImages.current = true; // Mark initial images as loaded
    }
  }, [initialImages, setFileList]);

  // Handle file change (Upload, Update, Delete)
  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };

  // Handle preview modal visibility
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj); // Convert to base64 for preview
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  // Close the preview modal
  const handleCancel = () => setPreviewVisible(false);

  // Helper function to convert image to Base64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Handle image removal
  const handleRemove = (file) => {
    if (file.id || file.url) {
      // This is an existing image, move it to deletedImages array
      setDeletedImages((prev) => [...prev, file]);
    }
    // Remove the image from the displayed list
    setFileList(fileList.filter((item) => item.uid !== file.uid));
    message.success('Image removed');
  };

  // Handle file upload before adding it to the list
  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      message.error('You can only upload image files!');
    }
    return isImage || Upload.LIST_IGNORE;
  };

  return (
    <div>
      <Upload
        multiple // Allow selecting multiple images at once
        listType="picture-card" // Allows displaying thumbnails of uploaded images
        fileList={fileList} // Controlled by parent
        onPreview={handlePreview} // Preview the image
        onChange={handleChange} // Update the file list when images are uploaded or removed
        beforeUpload={() => false} // Validate file before upload
        onRemove={handleRemove} // Handle file removal
      >
        {fileList.length >= 8 ? null : (
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        )}
      </Upload>

      {/* Modal to preview image */}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default ImagesUploader;
