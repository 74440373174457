import React, { useContext, useEffect } from 'react';
import { Row, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useMenu, useFetch } from '../../hooks';
import { LayoutContext } from '../../contexts';
import { URLS } from '../../constants';
import CreateUpdateSubcategoryForm from '../../components/CreateUpdateSubcategoryForm';

function SubcategoryCreate({ history }) {
  const navigate = useNavigate();
  const { setHeaderComponent } = useContext(LayoutContext);
  const { doFetch: createSubcategoryFetch } = useFetch();
  const { doFetch: uploadSubcategoryImageFetch } = useFetch();
  const menuData = useMenu();

  useEffect(() => {
    setHeaderComponent('Create New Subcategory');

    return () => {
      setHeaderComponent(null);
    };
  }, []);

  const uploadSubcategoryImage = async (subcategoryData, formValues) => {
    if(!formValues.image) {
      notification.success({
        placement: 'bottomRight',
        message: 'Subcategory created successfully.',
        duration: 3,
      });
      navigate('/subcategories');
      return;
    }

    await uploadSubcategoryImageFetch({
      method: 'FILE_POST',
      url: URLS.uploadSubcategoryImage({ subcategoryId: subcategoryData.id }),
      params: { image: formValues?.image?.file?.originFileObj },
      showSuccessNotification: true,
      successMessage: 'Subcategory created successfully.',
      onSuccess: () => {
        navigate('/subcategories');
      },
    });
  };

  const createNewSubcategory = async formValues => {
    const translationsObject = menuData.languageList.reduce((acc, language) => {
      acc[language.id] = formValues[`name.${language.id}`];
      return acc;
    }, {});

    console.log('translationsObject: ', translationsObject);
    console.log('formValues: ', formValues);

    await createSubcategoryFetch({
      url: URLS.createSubcategory({ categoryId: formValues.categoryId }),
      params: { ...translationsObject, tag_ids: formValues.tagIds },
      onSuccess: data => uploadSubcategoryImage(data, formValues),
    });
  };

  return (
    <Row>
      <CreateUpdateSubcategoryForm
        tagList={menuData.tagList}
        categoryList={menuData.categoryList}
        languageList={menuData.languageList}
        onSubmit={createNewSubcategory}
      />
    </Row>
  );
}

export default SubcategoryCreate;
