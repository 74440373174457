import { getCategoryImageUrl, getProductImageUrl, getTagImageUrl, createHash, getSubcategoryImageUrl } from './helpers';

export const prepareMenuListData = (menuData, selectedLanId) => {
  if (!menuData || !selectedLanId) return [];
  const lanObj = createHash(menuData.languages, 'id').data;
  const menuList = menuData.categories.reduce((acc, cat) => {
    const categoryObj = {
      id: cat.id,
      image: getCategoryImageUrl(cat.id),
      name: cat.category_translations.find(cat1 => cat1.language_id === selectedLanId).name_text,
      nameTranslations: cat.category_translations.reduce((acc, catTranslation) => {
        acc += `${lanObj[catTranslation.language_id].name}: ${catTranslation.name_text}<br />`;
        return acc;
      }, ''),
      tags: cat.tags.reduce((acc, tag) => {
        const tagObj = {
          id: tag.id,
          image: getTagImageUrl(tag.id),
          name: tag.tag_translations.find(
            tagTranslation => tagTranslation.language_id === selectedLanId
          ).name_text,
          nameTranslations: tag.tag_translations.reduce((acc, tagTranslation) => {
            acc += `${lanObj[tagTranslation.language_id].name}: ${tagTranslation.name_text}<br />`;
            return acc;
          }, ''),
        };
        acc.push(tagObj);
        return acc;
      }, []),
      products: cat.products.reduce((acc, product) => {
        const tagObj = {
          ...product,
          image: getProductImageUrl(product.id),
          name: product.product_translations.find(
            productTranslation => productTranslation.language_id === selectedLanId
          ).name_text,
          nameTranslations: product.product_translations.reduce((acc, productTranslation) => {
            acc += `${lanObj[productTranslation.language_id].name}: ${
              productTranslation.name_text
            }<br />`;
            return acc;
          }, ''),
          description: product.product_translations.find(
            productTranslation => productTranslation.language_id === selectedLanId
          ).description_text,
          descriptionTranslations: product.product_translations.reduce(
            (acc, productTranslation) => {
              acc += `${lanObj[productTranslation.language_id].name}: ${
                productTranslation.description_text
              }<br />`;
              return acc;
            },
            ''
          ),
        };
        acc.push(tagObj);
        return acc;
      }, []),
    };
    acc.push(categoryObj);
    return acc;
  }, []);
  return menuList;
};

export const getProductsCount = menuData => {
  if (!menuData) return 0;
  return menuData.categories.reduce((acc, cat) => {
    acc += cat.products.length;
    return acc;
  }, 0);
};

export const prepareCategoryList = menuData => {
  if (!menuData) return 0;
  const lanObj = createHash(menuData.languages, 'id').data;
  return menuData.categories.map(cat => ({
    id: cat.id,
    translations: cat.category_translations.map(translation => ({
      lanId: translation.language_id,
      lanName: lanObj[translation.language_id].name,
      name: translation.name_text,
    })),
    image: getCategoryImageUrl(cat.id),
    tags: cat.tags,
    productsCount: cat.products.length,
  }));
};

export const prepareSubcategoryList = menuData => {
  if (!menuData) return 0;
  const lanObj = createHash(menuData.languages, 'id').data;
  return menuData.subcategories.map(cat => ({
    id: cat.id,
    translations: cat.subcategory_translations.map(translation => ({
      lanId: translation.language_id,
      lanName: lanObj[translation.language_id].name,
      name: translation.name_text,
    })),
    image: getSubcategoryImageUrl(cat.id),
    product_priorities: cat.product_priorities,
  }));
};

export const prepareTagList = menuData => {
  if (!menuData) return 0;
  const lanObj = createHash(menuData.languages, 'id').data;
  return menuData.tags.map(tag => ({
    id: tag.id,
    translations: tag.tag_translations.map(translation => ({
      lanId: translation.language_id,
      lanName: lanObj[translation.language_id].name,
      name: translation.name_text,
    })),
    image: getTagImageUrl(tag.id),
  }));
};

export const prepareSelectedCategory = (menuData, categoryId) => {
  if (!menuData || !categoryId) return {};
  const categoryList = prepareCategoryList(menuData);
  const categoryItem = categoryList?.find(cat => cat.id === Number(categoryId));
  if (!categoryItem) return {};
  const namesObj = categoryItem.translations.reduce((acc, translation) => {
    acc[`name.${translation.lanId}`] = translation.name;
    return acc;
  }, {});
  return {
    ...namesObj,
    id: categoryItem?.id,
    tagIds: categoryItem?.tags?.map(tag => tag.id),
    image: categoryItem?.image,
  };
};

export const prepareSelectedSubcategory = (menuData, subcategoryId) => {
  if (!menuData || !subcategoryId) return {};
  const subcategoryList = prepareSubcategoryList(menuData);
  const subcategoryItem = subcategoryList?.find(cat => cat.id === Number(subcategoryId));
  if (!subcategoryItem) return {};
  const namesObj = subcategoryItem.translations.reduce((acc, translation) => {
    acc[`name.${translation.lanId}`] = translation.name;
    return acc;
  }, {});
  return {
    ...namesObj,
    id: subcategoryItem?.id,
    image: subcategoryItem?.image,
  };
};

export const prepareSelectedTag = (menuData, tagId) => {
  if (!menuData || !tagId) return {};
  const tagList = prepareTagList(menuData);
  const tagItem = tagList?.find(cat => cat.id === Number(tagId));
  if (!tagItem) return {};
  const namesObj = tagItem.translations.reduce((acc, translation) => {
    acc[`name.${translation.lanId}`] = translation.name;
    return acc;
  }, {});
  return {
    ...namesObj,
    id: tagItem?.id,
    image: tagItem?.image,
  };
};

export const prepareSelectedProduct = (menuData, productId) => {
  if (!menuData || !productId) return {};
  const productList = menuData.categories.reduce((acc, category) => {
    acc.push(...category.products);
    return acc;
  }, []);
  console.log('productListproductListproductListproductListproductList', productList);
  const selectedProduct = productList.find(product => product.id === Number(productId));
  console.log('selectedProduct: ', selectedProduct);
  if (!selectedProduct) return {};
  const namesDescriptionsObj = selectedProduct.product_translations.reduce((acc, translation) => {
    acc[`name.${translation.language_id}`] = translation.name_text;
    acc[`description.${translation.language_id}`] = translation.description_text;
    return acc;
  }, {});
  console.log('namesDescriptionsObj', namesDescriptionsObj);
  return {
    ...namesDescriptionsObj,
    id: selectedProduct.id,
    image: getProductImageUrl(selectedProduct.id),
    price: selectedProduct.price,
    currency: selectedProduct.currency,
    categoryId: selectedProduct.category_id,
    subcategoryId: selectedProduct.subcategory_id,
    isDeliverable: selectedProduct.is_deliverable,
    isInStock: selectedProduct.is_in_stock,
    isDinning: selectedProduct.is_dinning,
    images: selectedProduct.images,
    types: selectedProduct.types ? JSON.parse(selectedProduct.types) : undefined,
    colors: selectedProduct.colors ? JSON.parse(selectedProduct.colors) : undefined,
    sizes: selectedProduct.sizes ? JSON.parse(selectedProduct.sizes) : undefined,
  };
};
