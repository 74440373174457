import React from 'react';
import { Navigate, Link, useLocation, useNavigate } from 'react-router-dom';
import store from 'store';
import { Form, Input, Button, Layout, Row } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import '../../assets/scss/login.scss';

import { useFetch } from '../../hooks';
import { setAuthorizationToken } from '../../utils/API';
import { URLS } from '../../constants';

function Login({ 
  // location: { state: { registeredUserEmail } = {} } = {},
  // // location, 
  // ...rest
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const registeredUserEmail = state?.registeredUserEmail;

  console.log(location);
  // console.log(rest);

  const [form] = Form.useForm();
  const { validateFields } = form;
  const authToken = !!store.get('authenticationToken');
  const {
    data: { token, user: { firstname } = {} },
    doFetch,
  } = useFetch();

  if (token || token === 'null') {
    store.set('authenticationToken', token);
    store.set('username', firstname);
    setAuthorizationToken(token);
    window.location.reload(true);
  }

  const handleSubmit = values => {
    doFetch({
      url: URLS.login,
      params: values,
    });
  };

  console.log('registeredUserEmail', registeredUserEmail);

  return token || authToken ? (
    <Navigate to="/" />
  ) : (
    <Layout className="login-layout">
      <img style={{ height: 60, width: 60 }} src={require('../../assets/img/favicon.png')} alt="" />
      <Form name="loginForm" onFinish={handleSubmit} className="login-form">
        <Form.Item
          name="email"
          initialValues={{ email: registeredUserEmail }}
          rules={[{ required: true, message: 'Please enter your email!' }]}>
          <Input
            prefix={<UserOutlined type="user" className="input-icon" />}
            placeholder="Email"
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please enter your Password!' }]}>
          <Input
            prefix={<LockOutlined type="lock" className="input-icon" />}
            type="password"
            placeholder="Password"
            size="large"
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" block>
          Log in
        </Button>
        <Row style={{ paddingTop: 12 }}>
          <Link to="/sign-up">Sign Up</Link>
        </Row>
      </Form>
    </Layout>
  );
}

export default Login;
