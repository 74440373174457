import React, { useContext, useEffect, useState } from 'react';
import { Button, Row, Card, Image, Tooltip, Typography, Col, Modal } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FolderAddOutlined,
  DragOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { useMenu, useFetch } from '../../hooks';
import { LayoutContext } from '../../contexts';
import { URLS } from '../../constants';
import DndList from '../../components/DndList/DndList';

const { Meta } = Card;
const { Title, Text } = Typography;

function Subcategories({ history }) {
  const navigate = useNavigate();
  const { setHeaderComponent } = useContext(LayoutContext);
  const [selectedItemDelete, setSelectedItemToDelete] = useState();
  const [sortMode, setSortMode] = useState(false);
  const { doFetch: fetchDelete } = useFetch();
  const { doFetch: fetchSort } = useFetch();
  const menuData = useMenu();

  useEffect(() => {
    setHeaderComponent('Subcategory List');

    return () => {
      setHeaderComponent(null);
    };
  }, []);

  const handleItemDelete = () => {
    fetchDelete({
      url: URLS.deleteSubcategory({
        subcategoryId: selectedItemDelete.id,
      }),
      method: 'DELETE',
      onSuccess: () => {
        menuData.refresh();
        setSelectedItemToDelete();
      },
    });
  };

  const handleSortChange = newList => {
    fetchSort({
      url: URLS.subcategoryPrioritiesUpdate,
      method: 'POST',
      params: {
        priorities: newList.map(cat => cat.id),
      },
      onSuccess: () => {
        setSortMode(false);
        menuData.refresh();
      },
    });
  };

  return (
    <Row>
      <Row className="m-b-30 flex-space-between">
        <Title level={4} className="m-b-15">
          Available Subcategories:
        </Title>
        <Row type="flex" justify="end" gutter={[8, 0]}>
          <Col>
            <Button
              type="primary"
              icon={<FolderAddOutlined />}
              onClick={() => navigate('/subcategory-create')}>
              Add New Subcategory
            </Button>
          </Col>
          <Col>
            <Button
              type={sortMode ? 'dashed' : 'primary'}
              icon={sortMode ? <LeftOutlined /> : <DragOutlined />}
              onClick={() => {
                sortMode ? setSortMode(false) : setSortMode(true);
              }}>
              {sortMode ? 'Back' : 'Sort'}
            </Button>
          </Col>
        </Row>
      </Row>
      <Row gutter={[20, 20]} style={{ overflowX: 'auto' }}>
        <DndList
          list={menuData.subcategoryList}
          onChange={handleSortChange}
          sortMode={sortMode}
          renderItem={cat => (
            <Col {...(sortMode ? { style: { width: '220px' } } : { span: 4 })} key={cat.id}>
              <Card
                cover={
                  <Image alt="subcategory image" className="product-card-image" src={cat.image} />
                }
                actions={[
                  <Tooltip placement="top" title="Delete">
                    <DeleteOutlined
                      type="delete"
                      key="delete"
                      onClick={() => setSelectedItemToDelete(cat)}
                    />
                  </Tooltip>,
                  <Tooltip placement="top" title="Update">
                    <EditOutlined
                      type="edit"
                      key="edit"
                      onClick={() => navigate(`/subcategory/${cat.id}`)}
                    />
                  </Tooltip>,
                ]}>
                <Meta
                  title={cat.translations.map((translation, index) => (
                    <Row key={index}>
                      <Text>
                        <b>{translation.lanName}:</b> {translation.name}
                      </Text>
                    </Row>
                  ))}
                />
              </Card>
            </Col>
          )}
        />
      </Row>
      <Modal
        title={`Delete subcategory`}
        open={!!selectedItemDelete?.id}
        onOk={handleItemDelete}
        onCancel={() => setSelectedItemToDelete()}>
        This subcategory has {selectedItemDelete?.productsCount} product, Do you confirm deleteing the
        subcategory with all its products?
      </Modal>
    </Row>
  );
}

export default Subcategories;
