import React, { useContext, useEffect, useState } from 'react';
import { Button, Row, Card, Image, Tooltip, Typography, Col, Modal, Form, Input, Upload, message } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FolderAddOutlined,
  DragOutlined,
  LeftOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../utils/helpers';

import { useMenu, useFetch } from '../../hooks';
import { LayoutContext } from '../../contexts';
import { URLS } from '../../constants';
import DndList from '../../components/DndList/DndList';
import axios from 'axios';
import store from 'store';

const { Meta } = Card;
const { Title, Text } = Typography;

const authToken = store.get('authenticationToken');

function Subcategories({ history }) {
  const navigate = useNavigate();
  const { setHeaderComponent } = useContext(LayoutContext);
  const menuData = useMenu();

  useEffect(() => {
    setHeaderComponent('Send Marketing Message');

    return () => {
      setHeaderComponent(null);
    };
  }, []);

  const [file, setFile] = useState(null);
  const [twilioAccountSid, setTwilioAccountSid] = useState('');
  const [twilioAuthToken, setTwilioAuthToken] = useState('');
  const [twilioWhatsappNumber, setTwilioWhatsappNumber] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [mediaUrl, setMediaUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (info) => {
    console.log(info);
    setFile(info.file);
  };

  const handleSubmit = async () => {
    if (!file) {
      message.error('Please upload a CSV file.');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('twilio_account_sid', twilioAccountSid);
    formData.append('twilio_auth_token', twilioAuthToken);
    formData.append('twilio_whatsapp_number', twilioWhatsappNumber);
    formData.append('file', file);
    formData.append('message_body', messageBody);
    if (mediaUrl) formData.append('media_url', mediaUrl);

    try {
      const response = await axios.post(`${baseURL}/${URLS.whatsappSendMarketingMessage()}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `${authToken}`
        },
      });
      message.success(response.data.message);
    } catch (error) {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.error && data.invalid_numbers) {
          message.error(
            `Error: ${data.error}. Invalid numbers: ${data.invalid_numbers.join(
              ', '
            )}`
          );
        } else if (data.error && data.details) {
          let errorDetailsString = '';
          for (let i = 0; i < data.details.length; i++) {
            errorDetailsString += `${data.details[i].to}: ${data.details[i].error}\n\n`
          }
          message.error(
            `Error: ${data.error}. Details:\n\n${errorDetailsString}`
          );
        } else {
          message.error(data.error || 'Something went wrong');
        }
      } else {
        message.error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        label="Twilio Account SID"
        required
        tooltip="The Twilio Account SID can be found in the dashboard of Twilio"
      >
        <Input
          value={twilioAccountSid}
          onChange={(e) => setTwilioAccountSid(e.target.value)}
          placeholder="Enter your Twilio Account SID"
        />
      </Form.Item>

      <Form.Item
        label="Twilio Auth Token"
        required
        tooltip="The Twilio Auth Token can be found in the dashboard of Twilio"
      >
        <Input
          value={twilioAuthToken}
          onChange={(e) => setTwilioAuthToken(e.target.value)}
          placeholder="Enter your Twilio Auth Token"
        />
      </Form.Item>

      <Form.Item
        label="Twilio WhatsApp Number"
        required
        tooltip="The Twilio WhatsApp Number can be found in the dashboard of Twilio"
      >
        <Input
          value={twilioWhatsappNumber}
          onChange={(e) => setTwilioWhatsappNumber(e.target.value)}
          placeholder="Enter your Twilio WhatsApp Number"
        />
      </Form.Item>

      <Form.Item
        label="Message Body"
        required
        tooltip="This will be the text message sent via WhatsApp"
      >
        <Input.TextArea
          rows={4}
          value={messageBody}
          onChange={(e) => setMessageBody(e.target.value)}
          placeholder="Enter your marketing message"
          required
        />
      </Form.Item>

      <Form.Item
        label="Media URL (Optional)"
        tooltip="You can include an image or media URL to be sent along with the message"
      >
        <Input
          value={mediaUrl}
          onChange={(e) => setMediaUrl(e.target.value)}
          placeholder="Enter the URL of the media"
        />
      </Form.Item>

      <Form.Item
        label="Upload CSV File"
        required
        tooltip="Upload a CSV file containing the phone numbers in the first column. The format must contain country code and national number: +964XXXXXXXXX "
      >
        <Upload
          accept=".csv"
          beforeUpload={() => false} // Prevent automatic upload
          onChange={handleFileChange}
          fileList={file ? [file] : []}
        >
          <Button icon={<UploadOutlined />}>Select CSV File</Button>
        </Upload>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          Send WhatsApp Messages
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Subcategories;
