import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function DndList({
  list = [],
  draggableKey = 'id',
  onChange,
  renderItem,
  direction = 'horizontal',
  sortMode,
}) {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const newList = reorder(list, result.source.index, result.destination.index);
    onChange(newList);
  };

  if (!sortMode) {
    return list.map((item, index) => renderItem(item, index));
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={draggableKey} direction={direction}>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps} style={{ display: 'flex' }}>
              {list.map((item, index) => (
                <Draggable key={index} draggableId={`${item[draggableKey]}`} index={index}>
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      {renderItem(item)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default DndList;
