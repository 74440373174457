import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Image, Row, Card, Divider, Tooltip, Col, notification } from 'antd';
import { EditOutlined, LeftOutlined, DragOutlined } from '@ant-design/icons';

import CreateUpdateCategoryForm from '../../components/CreateUpdateCategoryForm';

import { useFetch, useMenu } from '../../hooks';
import { LayoutContext } from '../../contexts';
import { URLS } from '../../constants';
import DndList from '../../components/DndList/DndList';

const { Meta } = Card;

function SubcategoryDetail({
  // history,
  // match: {
  //   params: { id: subcategoryId },
  // },
}) {
  const { id } = useParams();
  const subcategoryId = id;
  const navigate = useNavigate();
  const { setHeaderComponent } = useContext(LayoutContext);
  const [sortMode, setSortMode] = useState(false);
  const { doFetch: updateSubcategoryFetch } = useFetch();
  const { doFetch: uploadSubcategoryImageFetch } = useFetch();
  const { doFetch: fetchSort } = useFetch();
  const menuData = useMenu({
    subcategoryId,
  });
  console.log('menuData: ', menuData);

  useEffect(() => {
    setHeaderComponent(<b>{`Subcategory ID: ${subcategoryId}`}</b>);

    return () => {
      setHeaderComponent(null);
    };
  }, []);

  const uploadSubcategoryImage = async (subcategoryData, formValues) => {
    if (!formValues?.image?.file?.originFileObj) {
      notification.success({
        placement: 'bottomRight',
        message: 'Subcategory updated successfully.',
        duration: 3,
      });
      return;
    }

    await uploadSubcategoryImageFetch({
      method: 'FILE_POST',
      url: URLS.uploadSubcategoryImage({ subcategoryId: subcategoryId }),
      params: { image: formValues?.image?.file?.originFileObj },
      showSuccessNotification: true,
      successMessage: 'Subcategory updated successfully.',
      onSuccess: () => {
        navigate('/subcategories');
      },
    });
  };

  const updateSubcategory = async formValues => {
    const translationsObject = menuData.languageList.reduce((acc, language) => {
      acc[language.id] = { name_text: formValues[`name.${language.id}`] };
      return acc;
    }, {});

    await updateSubcategoryFetch({
      url: URLS.updateSubcategory({ subcategoryId }),
      method: 'PUT',
      params: { ...translationsObject },
      onSuccess: data => uploadSubcategoryImage(data, formValues),
    });
  };

  const handleSortChange = newList => {
    fetchSort({
      url: URLS.productPrioritiesForSubcategoryUpdate({ subcategoryId }),
      method: 'POST',
      params: {
        priorities: newList.map(subcat => subcat.id),
      },
      onSuccess: () => {
        setSortMode(false);
        menuData.refresh();
      },
    });
  };

  const customOrder = menuData.subcategoryList.filter(subcat => subcat.id == subcategoryId)[0]?.product_priorities || [];

  console.log(menuData.subcategoryList.filter(subcat => subcat.id == subcategoryId))

  function customSort(a, b) {
    if (customOrder.includes(a.id) && customOrder.includes(b.id)) {
      // Both objects have id in the custom order
      const indexA = customOrder.indexOf(a.id);
      const indexB = customOrder.indexOf(b.id);
      return indexA - indexB;
    } else if (customOrder.includes(a.id)) {
      // Only the first object has id in the custom order
      return -1;
    } else if (customOrder.includes(b.id)) {
      // Only the second object has id in the custom order
      return 1;
    } else {
      // Both objects are outside the custom order, maintain their original order
      return 0;
    }
  }

  const allProducts = [];
  allProducts.push(...menuData.menuList.map(cat => cat.products));
  console.log(allProducts.flat());
  let productList =
    allProducts.flat().filter((product) => product.subcategory_id == subcategoryId) || [];
  productList.sort(customSort);
  console.log(customOrder);
  console.log('productList: ', productList);
  return (
    <>
      <Row>
        {menuData.selectedItem?.id && (
          <CreateUpdateCategoryForm
            selectedCategoryItem={menuData.selectedItem}
            tagList={menuData.tagList}
            categoryList={menuData.categoryList}
            languageList={menuData.languageList}
            onSubmit={updateSubcategory}
          />
        )}
      </Row>
      <Divider />
      <Button
        className="m-b-30"
        type={sortMode ? 'dashed' : 'primary'}
        icon={sortMode ? <LeftOutlined /> : <DragOutlined />}
        onClick={() => {
          sortMode ? setSortMode(false) : setSortMode(true);
        }}>
        {sortMode ? 'Back' : 'Sort'}
      </Button>
      <Row gutter={[20, 20]} style={{ overflowX: 'auto' }}>
        <DndList
          list={productList}
          onChange={handleSortChange}
          sortMode={sortMode}
          renderItem={product => (
            <Col {...(sortMode ? { style: { width: '220px' } } : { span: 4 })} key={product.id}>
              <Card
                className="product-card"
                cover={
                  <Image alt="product image" className="product-card-image" src={product.image} />
                }
                actions={[
                  <Tooltip placement="top" title="Update">
                    <Link to={`/product/${product.id}`}>
                      <EditOutlined type="edit" key="edit" />
                    </Link>
                  </Tooltip>,
                ]}>
                <Meta
                  title={
                    <Tooltip
                      placement="top"
                      title={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product.nameTranslations,
                          }}
                        />
                      }>
                      {product.name}
                    </Tooltip>
                  }
                  description={`${product.price} ${product.currency}`}
                />
              </Card>
            </Col>
          )}
        />
      </Row>
    </>
  );
}

export default SubcategoryDetail;
