import React, { useContext, Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Spin, notification } from 'antd';
import 'antd/dist/reset.css';
import store from 'store';

import { LayoutContext } from '../../contexts';
import { handleRequestError } from '../../utils/API';
import { baseRoutes, authRoutes } from '../../router';
import { URL_BASE_NAME } from '../../constants';

import '../../assets/scss/index.scss';
import MainLayout from '../../layout/MainLayout';

function AppContainer({ history }) {
  const authToken = store.get('authenticationToken');
  const username = store.get('username');

  const { loading, error, successNotification, setUsernameComponent } = useContext(LayoutContext);

  useEffect(() => {
    username && setUsernameComponent(username);

    return () => {
      setUsernameComponent(null);
    };
  }, []);

  useEffect(() => {
    const errorMessage = handleRequestError(error);
    const isAuthError = errorMessage === 'authentication failed';

    !!error &&
      !isAuthError &&
      notification.error({
        placement: 'bottomRight',
        message: 'An error occured!',
        duration: 3,
        description: errorMessage,
      });
    !!successNotification &&
      notification.success({
        placement: 'bottomRight',
        message: successNotification,
        duration: 3,
      });
    isAuthError && store.set('authenticationToken', null) && store.set('username', null);
  }, [error, successNotification, authToken]);

  return (
    <Fragment>
      <Spin spinning={loading}>
        <Router basename={URL_BASE_NAME}>
          <Routes>
            {baseRoutes.map(route => (
              <Route key={route.path} path={route.path} element={<route.component />} />
            ))}
            <Route path='/' element={<MainLayout/>}>
            {authRoutes.map((route) => (
              <Route
                // exact={true}
                key={route.path}
                path={route.path}
                element={<route.component />}
              />
            ))}
            </Route>
          </Routes>
          {/* {!authToken && <Navigate to="/login" />} */}
        </Router>
      </Spin>
    </Fragment>
  );
}

export default AppContainer;
