import React from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import store from 'store';
import { Form, Row, Input, Button, Layout, Typography } from 'antd';
import { UserOutlined, PushpinOutlined, LockOutlined } from '@ant-design/icons';
import '../../assets/scss/signUp.scss';

import { useFetch } from '../../hooks';
import { URLS } from '../../constants';

const { Title } = Typography;
const { TextArea } = Input;

const StyledFormItem = props => <Form.Item style={{ marginBottom: 6 }} {...props} />;

function SignUp({ history }) {
  const navigate = useNavigate();
  const authToken = !!store.get('authenticationToken');
  const { data: { email: registeredUserEmail } = {}, doFetch: doRegisterFetch } = useFetch();

  const handleSubmit = async values => {
    const { firstName, lastName, email, address, password } = values;

    doRegisterFetch({
      url: URLS.register,
      method: 'POST',
      params: {
        email: email,
        firstname: firstName,
        lastname: lastName,
        address: address,
        password: password,
      },
      onSuccess: () => {
        navigate({
          pathname: '/login',
          state: { registeredUserEmail },
        });
      },
      showSuccessNotification: true,
      successMessage: `You have successfully registered!`,
    });
  };

  return authToken ? (
    <Navigate to="/" />
  ) : (
    <Layout className="sign-up-layout">
      <img style={{ height: 45, width: 45 }} src={require('../../assets/img/favicon.png')} alt="" />
      <Form onFinish={handleSubmit} name="signUpForm" className="sign-up-form">
        <Title level={2} className="text-center">
          Sign Up
        </Title>
        <StyledFormItem
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: 'Please enter your first Name!' }]}>
          <Input
            prefix={<UserOutlined type="user" className="input-icon" />}
            placeholder="First Name"
          />
        </StyledFormItem>
        <StyledFormItem
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Please enter your last Name!' }]}>
          <Input
            prefix={<UserOutlined type="user" className="input-icon" />}
            placeholder="Last Name"
          />
        </StyledFormItem>
        <StyledFormItem
          label="Email Address"
          name="email"
          rules={[{ required: true, message: 'Please enter your email!' }]}>
          <Input prefix={<UserOutlined type="user" className="input-icon" />} placeholder="Email" />
        </StyledFormItem>
        <StyledFormItem
          label="Address"
          name="address"
          rules={[{ required: true, message: 'Please enter your address!' }]}>
          <TextArea
            rows={2}
            prefix={<PushpinOutlined type="pushpin" className="input-icon" />}
            placeholder="Address"
          />
        </StyledFormItem>
        <StyledFormItem
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please enter your Password!' }]}>
          <Input.Password
            prefix={<LockOutlined type="lock" className="input-icon" />}
            type="password"
            placeholder="Password"
          />
        </StyledFormItem>
        {/* <StyledFormItem
          label="Confirm Password"
          name="repeatedPassword"
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
              validator: (rule, value) => {
                if (!!value && getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('The two passwords that you entered do not match!');
              },
            },
          ]}>
          <Input.Password
            prefix={<LockOutlined type="lock" className="input-icon" />}
            type="password"
            placeholder="Password"
          />
        </StyledFormItem> */}

        <Button type="primary" htmlType="submit" block style={{ marginTop: 16 }}>
          Sign Up
        </Button>
        <Row style={{ paddingTop: 12, textAlign: 'center' }}>
          <Link to="/login">Login</Link>
        </Row>
      </Form>
    </Layout>
  );
}

export default SignUp;
