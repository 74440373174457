export const baseURL = `${
  /* window.location.hostname === 'localhost'
    ? '''http://localhost:5000'
    : window.location.origin */
    //'https://cors-anywhere.herokuapp.com/http://34.244.175.67:5000/'
    // 'http://45.93.136.183:5000/' // Production
    // 'http://45.93.136.183:9000/' // Development
    // 'http://localhost:9000/'
    'https://foodmenuapi.elements-creative.com/'
}`;

export const isEmail = email => /^[a-z0-9._%+-]+@.+\..+/.test(email);

export const createHash = (arr, key) => {
  var Hash = function() {
    this.data = {};
    if (arr && Array.isArray(arr)) {
      arr.forEach(o => {
        this.data[o[key]] = o;
      });
      this.size = arr.length;
    } else {
      this.size = 0;
    }
  };
  Hash.prototype.keys = function() {
    return Object.keys(this.data);
  };
  Hash.prototype.values = function() {
    return Object.values(this.data);
  };

  return new Hash();
};

export const getCategoryImageUrl = id =>
  `${baseURL}/files/category-images/${id}`;
export const getProductImageUrl = id =>
  `${baseURL}/files/product-images/${id}`;
export const getTagImageUrl = id => `${baseURL}/files/tag-images/${id}`;
export const getSubcategoryImageUrl = id =>
  `${baseURL}/files/subcategory-images/${id}`;
