import {
  PieChartOutlined,
  UnorderedListOutlined,
  AppstoreAddOutlined,
  EditOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import MainLayout from '../layout';
// GeneralViews
import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import Menu from '../pages/Menu';
import CategoryDetail from '../pages/CategoryDetail';
import ProductDetail from '../pages/ProductDetail';
import CategoryCreate from '../pages/CategoryCreate';
import ProductCreate from '../pages/ProductCreate';
import Categories from '../pages/Categories';
import Subcategories from '../pages/Subcategories';
import SubcategoryCreate from '../pages/SubcategoryCreate';
import SubcategoryDetail from '../pages/SubcategoryDetail';
import SendMarketingMessage from '../pages/SendMarketingMessage';
import Banners from '../pages/Banners';
//import Tags from '../pages/Tags';
//import TagDetail from '../pages/TagDetail';
//import TagCreate from '../pages/TagCreate';


export const authRoutes = [
  {
    path: '/',
    component: Menu,
    name: 'Menu',
    icon: PieChartOutlined,
    showAlways: true,
  },
  {
    path: '/banners',
    component: Banners,
    name: 'Banners',
    icon: UnorderedListOutlined,
    showAlways: true,
  },
  {
    path: '/categories',
    component: Categories,
    name: 'Categories',
    icon: UnorderedListOutlined,
    showAlways: true,
  },
  {
    path: '/subcategories',
    component: Subcategories,
    name: 'Subcategories',
    icon: UnorderedListOutlined,
    showAlways: true,
  },
  {
    path: '/whatsapp/send-marketing-message',
    component: SendMarketingMessage,
    name: 'Send Marketing Message',
    icon: MessageOutlined,
    showAlways: true,
  },
  {
    path: '/product-create',
    component: ProductCreate,
    name: 'Product Create',
    icon: AppstoreAddOutlined,
    showAlways: false,
  },
  {
    path: '/category-create',
    component: CategoryCreate,
    name: 'Category Create',
    icon: AppstoreAddOutlined,
    showAlways: false,
  },
  {
    path: '/subcategory-create',
    component: SubcategoryCreate,
    name: 'Subcategory Create',
    icon: AppstoreAddOutlined,
    showAlways: false,
  },
  {
    path: '/product/:id',
    component: ProductDetail,
    name: 'Product Detail',
    icon: EditOutlined,
    showAlways: false,
  },
  {
    path: '/category/:id',
    component: CategoryDetail,
    name: 'Category Detail',
    icon: EditOutlined,
    showAlways: false,
  },
  // TODO: subcategory detail
  {
    path: '/subcategory/:id',
    component: SubcategoryDetail,
    name: 'Subcategory Detail',
    icon: EditOutlined,
    showAlways: false,
  },
  /* {
    path: '/tags',
    component: Tags,
    name: 'Tags',
    icon: UnorderedListOutlined,
    showAlways: true,
  },
  {
    path: '/tag-create',
    component: TagCreate,
    name: 'Tag Create',
    icon: AppstoreAddOutlined,
    showAlways: false,
  },
  {
    path: '/tag/:id',
    component: TagDetail,
    name: 'Tag Detail',
    icon: EditOutlined,
    showAlways: false,
  }, */
];

export const baseRoutes = [
  {
    path: '/login',
    component: Login,
    name: 'Dashboard',
    noAuth: true,
  },
  {
    path: '/sign-up',
    component: SignUp,
    name: 'Sign Up',
    noAuth: true,
  },
  {
    path: '/',
    component: MainLayout,
    name: 'Main Layout',
  },
];
