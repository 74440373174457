import React, { useState, useContext } from 'react';
import { Layout, Icon, Modal } from 'antd';
import store from 'store';
import { Navigate } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';

import { LayoutContext } from '../contexts';

const { Header } = Layout;

function HeaderLayout() {
  const { headerComponent } = useContext(LayoutContext);

  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [isLogedOut, setLogedOut] = useState(false);

  const handleLogout = () => {
    store.set('authenticationToken', null);
    store.set('username', null);
    setLogedOut(true);
  };

  return (
    <Header>
      {isLogedOut && <Navigate to="/login" />}
      <div className="header-wrapper">
        <div className="page-header">{headerComponent}</div>
        <LogoutOutlined
          type="logout"
          onClick={() => setLogoutModalOpen(true)}
          className="logout-icon"
        />
      </div>
      <Modal
        title="Log out from Menu Portal"
        open={isLogoutModalOpen}
        onOk={handleLogout}
        onCancel={() => setLogoutModalOpen(false)}>
        would you really like to log out?
      </Modal>
    </Header>
  );
}

export default HeaderLayout;
