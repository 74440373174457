import React, { useContext } from "react";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { Layout } from "antd";

import SideBarLayout from "./SideBarLayout";
import HeaderLayout from "./HeaderLayout";
import FooterLayout from "./FooterLayout";

import { authRoutes } from "../router";
import { LayoutContext } from "../contexts";

const { Content } = Layout;

function MainLayout() {
  const { sideBarCollapsed } = useContext(LayoutContext);

  return (
    // <Layout style={{ marginLeft: sideBarCollapsed ? "80px" : "200px" }}>
    <Layout hasSider={true}>
      <SideBarLayout />
      <Layout style={{ marginLeft: 200 }}>
        <HeaderLayout />
        <Content style={{ minHeight: "calc(100vh - 160px)", height: "100%" }}>
          <Routes>
            {authRoutes.map((route) => (
              <Route
                exact={true}
                key={route.path}
                path={route.path}
                element={<route.component />}
              />
            ))}
            <Route path="*" />
          </Routes>
        </Content>
        <FooterLayout />
      </Layout>
    </Layout>
  );
}

export default MainLayout;
