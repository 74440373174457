import { useState, useEffect } from 'react';
import { notification } from 'antd';

import { useFetch } from '../hooks';
import { URLS } from '../constants';
import {
  prepareMenuListData,
  prepareCategoryList,
  prepareSubcategoryList,
  prepareTagList,
  prepareSelectedCategory,
  prepareSelectedTag,
  prepareSelectedProduct,
  prepareSelectedSubcategory,
} from '../utils/menu';

function useMenu({ categoryId, tagId, productId, subcategoryId } = {}) {
  const [menuList, setMenuList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [tagList, setTagList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [bannerImagesList, setBannerImagesList] = useState([]);

  const { doFetch: fetchMenu } = useFetch({
    defaultValue: { categories: [], languages: [], tags: [], subcategories: [], banner_images: [] },
  });

  const getMenuData = data => {
    setLanguageList(data.languages);
    setCategoryList(prepareCategoryList(data));
    setSubcategoryList(prepareSubcategoryList(data));
    setTagList(prepareTagList(data));
    setMenuList(prepareMenuListData(data, data?.languages[1]?.id));
    setBannerImagesList(data.banner_images);
    let selectedItemData = {};
    if(categoryId) {
      selectedItemData = prepareSelectedCategory(data, categoryId);
    }
    if(tagId) {
      selectedItemData = prepareSelectedTag(data, tagId);
    }
    if(productId) {
      selectedItemData = prepareSelectedProduct(data, productId);
    }
    if (subcategoryId) {
      selectedItemData = prepareSelectedSubcategory(data, subcategoryId);
    }
    
    if ((categoryId || tagId || productId || subcategoryId) && !selectedItemData.id) {
      notification.error({
        placement: 'bottomRight',
        message: 'Item not found!',
        duration: 3,
      });
    }
    setSelectedItem(selectedItemData);
  };

  const refresh = () => {
    fetchMenu({
      url: URLS.menu,
      onSuccess: getMenuData,
    });
  };

  useEffect(() => {
    fetchMenu({
      url: URLS.menu,
      onSuccess: getMenuData,
    });
  }, []);

  return {
    menuList,
    categoryList,
    subcategoryList,
    languageList,
    tagList,
    bannerImagesList,
    selectedItem,
    refresh
  };
}

export default useMenu;
