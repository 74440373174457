const URLS = {
  login: '/login',
  register: '/register',
  menu: '/menu',
  createTag: '/create-tag',
  getTagImage: ({ tagId }) => `/files/tag-images/${tagId}`,
  getTag: ({ tagId }) => `/tag/${tagId}`,
  createCategory: '/create-category',
  uploadCategoryImage: ({ categoryId }) => `/category/${categoryId}/image`,
  uploadTagImage: ({ tagId }) => `/tag/${tagId}/image`,
  uploadProductImage: ({ productId }) => `/product/${productId}/image`,
  deleteCategory: ({ categoryId }) => `/delete-category/${categoryId}`,
  deleteTag: ({ tagId }) => `/delete-tag/${tagId}`,
  getCategoryImage: ({ categoryId }) => `/files/category-images/${categoryId}`,
  getProduct: ({ productId }) => `/product/${productId}`,
  createProduct: ({ categoryId }) => `/create-product/${categoryId}`,
  updateProduct: ({ productId }) => `/product/${productId}`,
  updateCategory: ({ categoryId }) => `/category/${categoryId}`,
  updateTag: ({ tagId }) => `/tag/${tagId}`,
  deleteProductFetch: ({ productId, categoryId }) =>
    `category/${categoryId}/delete-product/${productId}`,
  getProductImage: ({ productId }) => `/files/product-images/${productId}`,
  updateProductTranslation: ({ productTranslationId }) =>
    `/product-translations/${productTranslationId}`,
  updateCategoryTranslation: ({ categoryTranslationId }) =>
    `/category-translations/${categoryTranslationId}`,
  updateTagTranslation: ({ tagTranslationId }) => `/tag-translations/${tagTranslationId}`,
  addCategoryToTag: ({ tagId }) => `/tag/${tagId}/add-category`,
  categoryPrioritiesUpdate: '/category-priorities/',
  subcategoryPrioritiesUpdate: '/subcategory-priorities/',
  productPrioritiesUpdate: ({ categoryId }) => `/product-priorities/${categoryId}`,
  deleteSubcategory: ({ subcategoryId }) => `/delete-subcategory/${subcategoryId}`,
  createSubcategory: ({ categoryId }) => `/create-subcategory/${categoryId}`,
  updateSubcategory: ({ subcategoryId }) => `/subcategory/${subcategoryId}`,
  uploadSubcategoryImage: ({ subcategoryId }) => `/subcategory/${subcategoryId}/image`,
  getSubcategoryImage: ({ subcategoryId }) => `/files/subcategory-images/${subcategoryId}`,
  productPrioritiesForSubcategoryUpdate: ({ subcategoryId }) => `/subcategory-product-priorities/${subcategoryId}`,
  whatsappSendMarketingMessage: () => `/whatsapp/send-marketing-message`,
  manageBannerImages: () => `/banner/manage-images`,
  manageProductImages: (productId) => `/product/${productId}/manage-images`,
};

export default URLS;
