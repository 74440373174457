import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { pathToRegexp } from 'path-to-regexp';

import { authRoutes } from '../router';
import { LayoutContext } from '../contexts';

const { Sider } = Layout;

function SideBarLayout() {
  const location = useLocation();
  const { pathname } = location;

  const isPathMatchRequestedUrl = path => !!pathToRegexp(path).exec(pathname);
  const { sideBarCollapsed, setCollapsed, theme, usernameComponent } = useContext(LayoutContext);

  return (
    <Sider
      collapsible
      collapsed={sideBarCollapsed}
      onCollapse={() => setCollapsed(!sideBarCollapsed)}
      width={200} 
      style={{ 
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
      }}
      >
      <div className="logo">
        <img src={require('../assets/img/favicon.png')} alt="" />
        {!sideBarCollapsed && <span> {usernameComponent}</span>}
      </div>
      <Menu theme={theme} mode="inline" activeKey={pathname} selectedKeys={[pathname]}>
        {authRoutes.map(({ path, showAlways, icon, name }) => (
          <Menu.Item key={isPathMatchRequestedUrl(path) ? pathname : path}>
            {(showAlways || isPathMatchRequestedUrl(path)) && (
              <Link to={path}>
                {icon && icon.render()}
                <span className="nav-text">{name}</span>
              </Link>
            )}
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
}

export default SideBarLayout;
