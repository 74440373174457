import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button, Image, Row, Card, Divider, Tooltip, Col, notification } from 'antd';
import { EditOutlined, LeftOutlined, DragOutlined } from '@ant-design/icons';

import CreateUpdateCategoryForm from '../../components/CreateUpdateCategoryForm';

import { useFetch, useMenu } from '../../hooks';
import { LayoutContext } from '../../contexts';
import { URLS } from '../../constants';
import DndList from '../../components/DndList/DndList';

const { Meta } = Card;

function CategoryDetail({
  // history,
  // match: {
  //   params: { id: categoryId },
  // },
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const categoryId = id;
  const { setHeaderComponent } = useContext(LayoutContext);
  const [sortMode, setSortMode] = useState(false);
  const { doFetch: updateCategoryFetch } = useFetch();
  const { doFetch: uploadCategoryImageFetch } = useFetch();
  const { doFetch: fetchSort } = useFetch();
  const menuData = useMenu({
    categoryId,
  });
  console.log('menuData: ', menuData);

  useEffect(() => {
    setHeaderComponent(<b>{`Category ID: ${categoryId}`}</b>);

    return () => {
      setHeaderComponent(null);
    };
  }, []);

  const uploadCategoryImage = async (categoryData, formValues) => {
    if (!formValues?.image?.file?.originFileObj) {
      notification.success({
        placement: 'bottomRight',
        message: 'Category updated successfully.',
        duration: 3,
      });
      return;
    }

    await uploadCategoryImageFetch({
      method: 'FILE_POST',
      url: URLS.uploadCategoryImage({ categoryId: categoryId }),
      params: { image: formValues?.image?.file?.originFileObj },
      showSuccessNotification: true,
      successMessage: 'Category updated successfully.',
      onSuccess: () => {
        navigate('/categories');
      },
    });
  };

  const updateCategory = async formValues => {
    const translationsObject = menuData.languageList.reduce((acc, language) => {
      acc[language.id] = { name_text: formValues[`name.${language.id}`] };
      return acc;
    }, {});

    await updateCategoryFetch({
      url: URLS.updateCategory({ categoryId }),
      method: 'PUT',
      params: { ...translationsObject, tag_ids: formValues.tagIds },
      onSuccess: data => uploadCategoryImage(data, formValues),
    });
  };

  const handleSortChange = newList => {
    fetchSort({
      url: URLS.productPrioritiesUpdate({ categoryId }),
      method: 'POST',
      params: {
        priorities: newList.map(cat => cat.id),
      },
      onSuccess: () => {
        setSortMode(false);
        menuData.refresh();
      },
    });
  };

  const productList =
    menuData.menuList.find(cat => cat.id === menuData?.selectedItem?.id)?.products || [];
  console.log('productList: ', productList);
  return (
    <>
      <Row>
        {menuData.selectedItem?.id && (
          <CreateUpdateCategoryForm
            selectedCategoryItem={menuData.selectedItem}
            tagList={menuData.tagList}
            categoryList={menuData.categoryList}
            languageList={menuData.languageList}
            onSubmit={updateCategory}
          />
        )}
      </Row>
      <Divider />
      <Button
        className="m-b-30"
        type={sortMode ? 'dashed' : 'primary'}
        icon={sortMode ? <LeftOutlined /> : <DragOutlined />}
        onClick={() => {
          sortMode ? setSortMode(false) : setSortMode(true);
        }}>
        {sortMode ? 'Back' : 'Sort'}
      </Button>
      <Row gutter={[20, 20]} style={{ overflowX: 'auto' }}>
        <DndList
          list={productList}
          onChange={handleSortChange}
          sortMode={sortMode}
          renderItem={product => (
            <Col {...(sortMode ? { style: { width: '220px' } } : { span: 4 })} key={product.id}>
              <Card
                className="product-card"
                cover={
                  <Image alt="product image" className="product-card-image" src={product.image} />
                }
                actions={[
                  <Tooltip placement="top" title="Update">
                    <Link to={`product/${product.id}`}>
                      <EditOutlined type="edit" key="edit" />
                    </Link>
                  </Tooltip>,
                ]}>
                <Meta
                  title={
                    <Tooltip
                      placement="top"
                      title={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product.nameTranslations,
                          }}
                        />
                      }>
                      {product.name}
                    </Tooltip>
                  }
                  description={`${product.price} ${product.currency}`}
                />
              </Card>
            </Col>
          )}
        />
      </Row>
    </>
  );
}

export default CategoryDetail;
