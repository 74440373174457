import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Button, Image, Row, Card, Select, Tooltip, Typography, Col, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, FolderAddOutlined } from '@ant-design/icons';

import { useFetch } from '../../hooks';
import { LayoutContext } from '../../contexts';
import { URLS } from '../../constants';
import { prepareMenuListData, getProductsCount } from '../../utils/menu';

const { Meta } = Card;
const { Title, Text } = Typography;
const { Option } = Select;

function Menu({ history }) {
  const navigate = useNavigate();
  const { setHeaderComponent } = useContext(LayoutContext);
  const [menuList, setMenuList] = useState([]);
  const [mainLanId, setMainLanId] = useState();
  const [menuData, setMenuData] = useState();
  const [selectedItemDelete, setSelectedItemToDelete] = useState();
  const { doFetch: fetchMenu } = useFetch({
    defaultValue: { categories: [], languages: [] },
  });
  const { doFetch: fetchDelete } = useFetch();

  const getMenuList = data => {
    setMenuData(data);
    const selectedLanId = mainLanId || data.languages[1].id;
    if (!mainLanId) {
      setMainLanId(data.languages[1].id); // 0 For English, 1 For Arabic, 2 For Kurdish.
    }
    setMenuList(prepareMenuListData(data, selectedLanId));
  };

  const getProductsFilter = () => {
    if (!menuData || !mainLanId) return null;
    return (
      <>
        <div className="filter-item">
          <b>Products count: {getProductsCount(menuData)}</b>
        </div>
        <div className="filter-item">
          <b>Selected language:</b>
          <Select
            value={mainLanId}
            className="filter-select"
            onChange={lanId => setMainLanId(lanId)}>
            {menuData.languages.map(language => (
              <Option value={language.id} key={language.id}>
                {language.name}
              </Option>
            ))}
          </Select>
        </div>
        {/* <div className="filter-item">
          <b>Search by name [WIP]:</b>
          <Input className="filter-by-name" onChange={() => {}} placeholder="product name" />
        </div> */}
      </>
    );
  };

  useEffect(() => {
    fetchMenu({
      url: URLS.menu,
      onSuccess: getMenuList,
    });

    return () => {
      setHeaderComponent(null);
    };
  }, []);

  useEffect(() => {
    if (mainLanId && menuData) {
      getMenuList(menuData);
      setHeaderComponent(getProductsFilter());
    }
  }, [mainLanId, menuData]);

  const handleItemDelete = () => {
    fetchDelete({
      url: URLS.deleteProductFetch({
        productId: selectedItemDelete.id,
        categoryId: selectedItemDelete.category_id,
      }),
      method: 'DELETE',
      onSuccess: () => {
        fetchMenu({
          url: URLS.menu,
          onSuccess: getMenuList,
        });
        setSelectedItemToDelete();
      },
    });
  };

  return (
    <>
      <Row type="flex" justify="end" gutter={[8, 0]} className="m-b-30">
        <Col>
          <Button
            type="primary"
            icon={<FolderAddOutlined />}
            onClick={() => navigate('/product-create')}>
            Add New Product
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<FolderAddOutlined />}
            onClick={() => navigate('/category-create')}>
            Add New Category
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<FolderAddOutlined />}
            onClick={() => navigate('/subcategory-create')}>
            Add New Subcategory
          </Button>
        </Col>
        {/* <Col>
          <Button
            type="primary"
            icon={<FolderAddOutlined />}
            onClick={() => navigate('/tag-create')}>
            Add New Tag
          </Button>
        </Col> */}
      </Row>

      {menuList.map(cat => (
        <Card
          key={cat.id}
          type="inner"
          title={
            <div className="category-card-header">
              <Avatar size={65} src={cat.image} className="m-r-10" />
              <div>
                <Tooltip
                  placement="top"
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: cat.nameTranslations,
                      }}
                    />
                  }>
                  <Title level={4}>{cat.name}</Title>
                </Tooltip>
                {/* {cat.tags.map(tag => (
                  <Tooltip
                    placement="top"
                    title={
                      <div
                        dangerouslySetInnerHTML={{
                          __html: tag.nameTranslations,
                        }}
                      />
                    }>
                    <Text code>{tag.name}</Text>
                  </Tooltip>
                ))} */}
              </div>
            </div>
          }
          extra={
            <>
              <Tooltip placement="top" title="Edit">
                <Link to={`category/${cat.id}`} className="m-r-10">
                  <Text>
                    <EditOutlined type="edit" key="edit" className="f-s-20" />
                  </Text>
                </Link>
              </Tooltip>
            </>
          }
          className="m-b-30">
          <Row gutter={[20, 20]}>
            {cat.products.map(product => (
              <Col span={4} key={product.id}>
                <Card
                  className="product-card"
                  cover={
                    <Image alt="product image" className="product-card-image" src={product.image} />
                  }
                  actions={[
                    <Tooltip placement="top" title="Delete">
                      <DeleteOutlined
                        type="delete"
                        key="delete"
                        onClick={() => setSelectedItemToDelete(product)}
                      />
                    </Tooltip>,
                    <Tooltip placement="top" title="Update">
                      <Link to={`product/${product.id}`}>
                        <EditOutlined type="edit" key="edit" />
                      </Link>
                    </Tooltip>,
                  ]}>
                  <Meta
                    title={
                      <Tooltip
                        placement="top"
                        title={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: product.nameTranslations,
                            }}
                          />
                        }>
                        {product.name}
                      </Tooltip>
                    }
                    description={`${product.price} ${product.currency}`}
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      ))}
      <Modal
        title={`Delete product: ${selectedItemDelete?.name}`}
        open={!!selectedItemDelete?.id}
        onOk={handleItemDelete}
        onCancel={() => setSelectedItemToDelete()}>
        Do you confirm deleteing the product?
      </Modal>
    </>
  );
}

export default Menu;
