import React, { useEffect, useState } from 'react';
import { Space, ConfigProvider, Button, Form, Input, Row, Select, Divider, Image, Upload, Col, Checkbox } from 'antd';
import { PlusOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { TinyColor } from '@ctrl/tinycolor';
import ImagesUploader from '../ImagesUploader';
import { baseURL } from '../../utils/helpers';

const { Option } = Select;
const { TextArea } = Input;

function CreateUpdateProductForm({
  selectedProductItem = {
    currency: 'IQD',
  },
  categoryList,
  subcategoryList,
  languageList,
  onSubmit,
}) {
  const isUpdate = !!selectedProductItem?.id;
  const [imageUrl, setImageUrl] = useState();
  const [isDeliverable, setIsDeliverable] = useState();
  const [isInStock, setIsInStock] = useState();
  const [isDinning, setIsDinning] = useState();

  const [uploadFileList, setUploadFileList] = useState([]);  // State in the parent to manage the file list
  const [deleteFileList, setDeleteFileList] = useState([]);

  console.log(selectedProductItem);

  const initialImages = isUpdate ? selectedProductItem.images.map((image) => ({
    uid: image.id, // Use the ID as uid to keep it unique
    name: `Image ${image.id}`, // Placeholder name
    status: 'done', // Mark these as already uploaded
    url: `${baseURL}/files/product-images/${selectedProductItem.id}/${image.id}`, // The link to the image
  })) : [];

  // Handle changes in the file list, passed to the child component
  const handleUploadFileListChange = (newFileList) => {
    setUploadFileList(newFileList); // Update the file list in the parent
  };

  const [productForm] = Form.useForm();
  const [typesForm] = Form.useForm();
  const [colorsForm] = Form.useForm();
  const [sizesForm] = Form.useForm();

  // const colors1 = ['#6253E1', '#04BEFE'];
  // const colors2 = ['#fc6076', '#ff9a44', '#ef9d43', '#e75516'];
  const colors3 = ['#40e495', '#30dd8a', '#2bb673'];
  const getHoverColors = (colors) =>
    colors.map((color) => new TinyColor(color).lighten(5).toString());
  const getActiveColors = (colors) =>
    colors.map((color) => new TinyColor(color).darken(5).toString());

  useEffect(() => {
    selectedProductItem.image && setImageUrl(selectedProductItem.image);
    // setIsDeliverable(selectedProductItem.isDeliverable);
    // setIsInStock(selectedProductItem.isInStock)
    // setIsDinning(selectedProductItem.isDinning)
    typesForm.setFieldsValue({ types: selectedProductItem.types })
    colorsForm.setFieldsValue({ colors: selectedProductItem.colors })
    sizesForm.setFieldsValue({ sizes: selectedProductItem.sizes })
    if (!isUpdate) {
      productForm.setFieldsValue({
        isDinning: true,
        isDeliverable: true,
        isInStock: true,
      })
    }
  }, [selectedProductItem, typesForm, colorsForm, sizesForm, productForm]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleSubmit = async values => {
    const types = await typesForm.validateFields();
    const colors = await colorsForm.validateFields();
    const sizes = await sizesForm.validateFields();
    values = {
      ...values,
      types: types.types,
      colors: colors.colors,
      sizes: sizes.sizes,
      uploadFileList: uploadFileList,
      deleteFileList: deleteFileList,
    }
    onSubmit(values);
  };

  const handleImageChange = info => {
    getBase64(info.file.originFileObj, imageUrl => setImageUrl(imageUrl));
  };

  return (
    <Form
      onFinish={handleSubmit}
      name="createUpdateProductForm"
      className="form-wrapper"
      layout="vertical"
      size="large"
      initialValues={selectedProductItem}
      form={productForm}
    >
      <Row>
        <Col span={12}>
          {languageList.map(lan => (
            <div key={lan.id}>
              <Form.Item
                name={`name.${lan.id}`}
                label={`Name in ${lan.name}`}
                rules={[{ required: true, message: `Please enter ${lan.name} product name!` }]}>
                <Input placeholder={`product name in ${lan.name}`} />
              </Form.Item>
              <Form.Item
                name={`description.${lan.id}`}
                label={`Description in ${lan.name}`}
                rules={[{ message: `Please enter ${lan.name} product description!` }]}>
                <TextArea rows={2} placeholder={`product description in ${lan.name}`} />
              </Form.Item>
              <Divider />
            </div>
          ))}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={6} className="m-r-10">
          <Form.Item
            name="price"
            label="Price"
            type="number"
            rules={[{ required: true, message: 'Please enter product price!' }]}>
            <Input placeholder="product price" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="currency"
            label="Currency"
            rules={[{ required: true, message: 'Please enter product currency!' }]}>
            <Select placeholder="Select a currency">
              <Option value="USD">USD</Option>
              <Option value="IQD">IQD</Option>
              <Option value="EUR">EUR</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="isDeliverable"
            valuePropName='checked'
          >
            <Checkbox>Is Deliverable</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            name="isDinning"
            valuePropName='checked'
          >
            <Checkbox>Is Dinning</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Form form={typesForm} name="dynamic_form" autoComplete="off">
          <Form.List name="types">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 20, marginTop: 0 }} align="baseline">
                    {languageList.map(lan => (
                      <Form.Item
                        {...restField}
                        key={`typeName.${lan.id}`}
                        label={`Type in ${lan.name}`}
                        name={[name, `typeName.${lan.id}`]}
                        fieldKey={[fieldKey, `typeName.${lan.id}`]}
                        rules={[{ required: true, message: 'Missing input' }]}
                      >
                        <Input placeholder={`Type in ${lan.name}`} />
                      </Form.Item>
                    ))}
                    <Form.Item
                      {...restField}
                      label="Price"
                      name={[name, 'price']}
                      fieldKey={[fieldKey, 'price']}
                      rules={[{ required: true, message: 'Missing input' }]}
                    >
                      <Input placeholder="Price" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorPrimary: `linear-gradient(116deg,  ${colors3.join(', ')})`,
                          colorPrimaryHover: `linear-gradient(116deg, ${getHoverColors(colors3).join(', ')})`,
                          colorPrimaryActive: `linear-gradient(116deg, ${getActiveColors(colors3).join(', ')})`,
                          lineWidth: 0,
                        },
                      },
                    }}
                  >
                    <Button onClick={() => add()} type="primary" size="large" icon={<PlusCircleOutlined />}>
                      Add New Type
                    </Button>
                  </ConfigProvider>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Row>
      <Divider />
      <Row>
        <Form form={colorsForm} name="dynamic_colors_form" autoComplete="off">
          <Form.List name="colors">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 20, marginTop: 0 }} align="baseline">
                    {languageList.map(lan => (
                      <Form.Item
                        {...restField}
                        key={`colorName.${lan.id}`}
                        label={`Color in ${lan.name}`}
                        name={[name, `colorName.${lan.id}`]}
                        fieldKey={[fieldKey, `colorName.${lan.id}`]}
                        rules={[{ required: true, message: 'Missing input' }]}
                      >
                        <Input placeholder={`Color in ${lan.name}`} />
                      </Form.Item>
                    ))}
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorPrimary: `linear-gradient(116deg,  ${colors3.join(', ')})`,
                          colorPrimaryHover: `linear-gradient(116deg, ${getHoverColors(colors3).join(', ')})`,
                          colorPrimaryActive: `linear-gradient(116deg, ${getActiveColors(colors3).join(', ')})`,
                          lineWidth: 0,
                        },
                      },
                    }}
                  >
                    <Button onClick={() => add()} type="primary" size="large" icon={<PlusCircleOutlined />}>
                      Add New Color
                    </Button>
                  </ConfigProvider>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Row>
      <Divider />
      <Row>
        <Form form={sizesForm} name="dynamic_sizes_form" autoComplete="off">
          <Form.List name="sizes">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 20, marginTop: 0 }} align="baseline">
                    {languageList.map(lan => (
                      <Form.Item
                        {...restField}
                        key={`sizeName.${lan.id}`}
                        label={`Size in ${lan.name}`}
                        name={[name, `sizeName.${lan.id}`]}
                        fieldKey={[fieldKey, `sizeName.${lan.id}`]}
                        rules={[{ required: true, message: 'Missing input' }]}
                      >
                        <Input placeholder={`Size in ${lan.name}`} />
                      </Form.Item>
                    ))}
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorPrimary: `linear-gradient(116deg,  ${colors3.join(', ')})`,
                          colorPrimaryHover: `linear-gradient(116deg, ${getHoverColors(colors3).join(', ')})`,
                          colorPrimaryActive: `linear-gradient(116deg, ${getActiveColors(colors3).join(', ')})`,
                          lineWidth: 0,
                        },
                      },
                    }}
                  >
                    <Button onClick={() => add()} type="primary" size="large" icon={<PlusCircleOutlined />}>
                      Add New Size
                    </Button>
                  </ConfigProvider>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Row>
      {/* <Row>
        <Col span={12}>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorPrimary: `linear-gradient(116deg,  ${colors3.join(', ')})`,
                  colorPrimaryHover: `linear-gradient(116deg, ${getHoverColors(colors3).join(', ')})`,
                  colorPrimaryActive: `linear-gradient(116deg, ${getActiveColors(colors3).join(', ')})`,
                  lineWidth: 0,
                },
              },
            }}
          >
            <Button type="primary" size="large" icon={<PlusCircleOutlined />}>
              Add New Type
            </Button>
          </ConfigProvider>
        </Col>
      </Row> */}
      {/* <Row>
        <Col span={12}>
          <Form.Item
            name="isInStock"
            valuePropName='checked'
          >
            <Checkbox defaultChecked={true}>Is In Stock</Checkbox>
          </Form.Item>
        </Col>
      </Row> */}
      {true && (
        <>
          <Divider />
          <Row>
            <Col span={12}>
              <Form.Item
                label="Category"
                name="categoryId"
                rules={[{ required: true, message: 'Please select the category!' }]}>
                <Select placeholder="Select a category">
                  {categoryList.map(tag => (
                    <Option value={tag.id} key={tag.id}>
                      {tag.translations.map((translation, index) => (
                        <>
                          {translation.lanName}: <b>{translation.name}</b>{' '}
                          {index !== tag.translations.length - 1 && '- '}
                        </>
                      ))}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      {true && (
        <>
          <Divider />
          <Row>
            <Col span={12}>
              <Form.Item
                label="Subcategory"
                name="subcategoryId"
                rules={[{ required: false, message: 'Please select the subcategory!' }]}>
                <Select placeholder="Select a subcategory">
                  {subcategoryList.map(tag => (
                    <Option value={tag.id} key={tag.id}>
                      {tag.translations.map((translation, index) => (
                        <>
                          {translation.lanName}: <b>{translation.name}</b>{' '}
                          {index !== tag.translations.length - 1 && '- '}
                        </>
                      ))}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Divider />
      <Row>
        <Col span={12}>
          <Form.Item label="Product Image:" name="image" className="product-image-upload">
            <Upload
              listType="picture-card"
              showUploadList={false}
              onPreview={file => console.log(file)}
              onChange={handleImageChange}>
              {imageUrl ? (
                <Image src={imageUrl} alt="avatar" style={{ width: '100%' }} preview={false} />
              ) : (
                <>
                  <PlusOutlined type="plus" />
                  <div className="ant-upload-text">Upload</div>
                </>
              )}
            </Upload>
          </Form.Item>
        </Col>
      </Row>

      <Divider />
      <Row>
        <Col span={12}>
          <Form.Item label="Additional Images:" className="product-image-upload">
          <ImagesUploader 
              fileList={uploadFileList} // Pass the uploadFileList to the child component
              setFileList={handleUploadFileListChange} // Pass the callback to update uploadFileList
              initialImages={initialImages}
              deletedImages={deleteFileList}
              setDeletedImages={setDeleteFileList}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="m-t-30 f-r">
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Save
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
}

export default CreateUpdateProductForm;
