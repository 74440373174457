import React, { useContext, useEffect } from 'react';
import { Row, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useMenu, useFetch } from '../../hooks';
import { LayoutContext } from '../../contexts';
import { URLS } from '../../constants';
import CreateUpdateCategoryForm from '../../components/CreateUpdateCategoryForm';

function CategoryCreate({ history }) {
  const navigate = useNavigate();
  const { setHeaderComponent } = useContext(LayoutContext);
  const { doFetch: createCategoryFetch } = useFetch();
  const { doFetch: uploadCategoryImageFetch } = useFetch();
  const menuData = useMenu();

  useEffect(() => {
    setHeaderComponent('Create New Category');

    return () => {
      setHeaderComponent(null);
    };
  }, []);

  const uploadCategoryImage = async (categoryData, formValues) => {
    if(!formValues.image) {
      notification.success({
        placement: 'bottomRight',
        message: 'Category created successfully.',
        duration: 3,
      });
      navigate('/categories');
      return;
    }

    await uploadCategoryImageFetch({
      method: 'FILE_POST',
      url: URLS.uploadCategoryImage({ categoryId: categoryData.id }),
      params: { image: formValues?.image?.file?.originFileObj },
      showSuccessNotification: true,
      successMessage: 'Category created successfully.',
      onSuccess: () => {
        navigate('/categories');
      },
    });
  };

  const createNewCategory = async formValues => {
    const translationsObject = menuData.languageList.reduce((acc, language) => {
      acc[language.id] = formValues[`name.${language.id}`];
      return acc;
    }, {});

    await createCategoryFetch({
      url: URLS.createCategory,
      params: { ...translationsObject, tag_ids: formValues.tagIds },
      onSuccess: data => uploadCategoryImage(data, formValues),
    });
  };

  return (
    <Row>
      <CreateUpdateCategoryForm
        tagList={menuData.tagList}
        categoryList={menuData.categoryList}
        languageList={menuData.languageList}
        onSubmit={createNewCategory}
      />
    </Row>
  );
}

export default CategoryCreate;
