import React, { useContext, useEffect } from 'react';
import { Row, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useMenu, useFetch } from '../../hooks';
import { LayoutContext } from '../../contexts';
import { URLS } from '../../constants';
import CreateUpdateProductForm from '../../components/CreateUpdateProductForm';
import axios from 'axios';
import store from 'store';
import { baseURL } from '../../utils/helpers';

const authToken = store.get('authenticationToken');

function ProductCreate({ history }) {
  const navigate = useNavigate();
  const { setHeaderComponent } = useContext(LayoutContext);
  const { doFetch: createProductFetch } = useFetch();
  const { doFetch: uploadProductImageFetch } = useFetch();
  const menuData = useMenu();

  useEffect(() => {
    setHeaderComponent('Create New Product');

    return () => {
      setHeaderComponent(null);
    };
  }, []);

  const uploadProductImage = async (productData, formValues) => {
    if (!formValues?.image?.file?.originFileObj && !(formValues.uploadFileList.length || formValues.deleteFileList.length)) {
      notification.success({
        placement: 'bottomRight',
        message: 'Product created successfully.',
        duration: 3,
      });
      navigate('/');
      return;
    } else if (!formValues?.image?.file?.originFileObj && (formValues.uploadFileList.length || formValues.deleteFileList.length)) {
      await updateOtherProductImages(productData, formValues);
      notification.success({
        placement: 'bottomRight',
        message: 'Product created successfully.',
        duration: 3,
      });
      navigate('/');
      return;
    } else if (formValues?.image?.file?.originFileObj && !(formValues.uploadFileList.length || formValues.deleteFileList.length)) {
      await uploadProductImageFetch({
        method: 'FILE_POST',
        url: URLS.uploadProductImage({
          productId: productData?.id,
        }),
        params: { image: formValues?.image?.file?.originFileObj },
        showSuccessNotification: true,
        successMessage: 'Product created successfully.',
        onSuccess: () => {
          navigate('/');
        },
      });
    } else {
      await updateOtherProductImages(productData, formValues);

      await uploadProductImageFetch({
        method: 'FILE_POST',
        url: URLS.uploadProductImage({
          productId: productData?.id,
        }),
        params: { image: formValues?.image?.file?.originFileObj },
        showSuccessNotification: true,
        successMessage: 'Product created successfully.',
        onSuccess: () => {
          navigate('/');
        },
      });
    }
  };

  const updateOtherProductImages = async (productData, formValues) => {
    const formData = new FormData();

    // Add new images (files) to formData
    formValues.uploadFileList.forEach((file) => {
      if (!file.url) { // Only add newly uploaded images, not existing ones
        formData.append('upload_images', file.originFileObj); // Ensure you're sending the file object
      }
    });

    // Add deleted image IDs to formData
    formValues.deleteFileList.forEach((file) => {
      formData.append('delete_images', file.uid); // Send the ID of the deleted images
    });

    try {
      const response = await axios.post(`${baseURL}/${URLS.manageProductImages(productData?.id)}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `${authToken}`,
        },
      });
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  };

  const createNewProduct = async formValues => {
    const translationsObject = menuData.languageList.reduce((acc, language) => {
      acc[language.id] = {
        name_text: formValues[`name.${language.id}`],
        description_text: formValues[`description.${language.id}`],
      };
      return acc;
    }, {});

    await createProductFetch({
      url: URLS.createProduct({ categoryId: formValues.categoryId }),
      params: { 
        ...translationsObject,
        price: formValues.price, 
        currency: formValues.currency, 
        subcategory_id: formValues.subcategoryId, 
        is_deliverable: formValues.isDeliverable, 
        // is_in_stock: formValues.isInStock, 
        is_dinning: formValues.isDinning,
        types: JSON.stringify(formValues.types),
        colors: JSON.stringify(formValues.colors),
        sizes: JSON.stringify(formValues.sizes),
      },
      onSuccess: data => uploadProductImage(data, formValues),
    });
  };

  return (
    <Row>
      <CreateUpdateProductForm
        categoryList={menuData.categoryList}
        subcategoryList={menuData.subcategoryList}
        languageList={menuData.languageList}
        onSubmit={createNewProduct}
      />
    </Row>
  );
}

export default ProductCreate;
